exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cobalt-jsx": () => import("./../../../src/pages/cobalt.jsx" /* webpackChunkName: "component---src-pages-cobalt-jsx" */),
  "component---src-pages-golem-jsx": () => import("./../../../src/pages/golem.jsx" /* webpackChunkName: "component---src-pages-golem-jsx" */),
  "component---src-pages-hive-jsx": () => import("./../../../src/pages/hive.jsx" /* webpackChunkName: "component---src-pages-hive-jsx" */),
  "component---src-pages-huxtaburger-jsx": () => import("./../../../src/pages/huxtaburger.jsx" /* webpackChunkName: "component---src-pages-huxtaburger-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-streamr-jsx": () => import("./../../../src/pages/streamr.jsx" /* webpackChunkName: "component---src-pages-streamr-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-vizor-jsx": () => import("./../../../src/pages/vizor.jsx" /* webpackChunkName: "component---src-pages-vizor-jsx" */)
}

